import React from 'react';
import { Helmet } from 'react-helmet'; // Importez Helmet
import logo from '../assets/logo2.webp';
import './styles/HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection({ isAside }) {
  return (
    <div className={`hero-container ${isAside ? 'aside' : ''}`}>
      {/* Balises Helmet pour le SEO */}
      <Helmet>
        <title>Les Enduits d'Eugénie - Béton ciré, enduits naturels et maçonnerie fine au Vigan, autour de Montpellier et Avignon</title>
        <meta
          name="description"
          content="Experte en béton ciré et enduits naturels, Les Enduits d'Eugénie intervient au Vigan, dans les Cévennes, autour de Montpellier et d'Avignon pour une maçonnerie de finition unique et durable."
        />
        <meta
          name="keywords"
          content="béton ciré, maçonnerie, enduits naturels, Le Vigan, Cévennes, Montpellier, Avignon, enduits décoratifs, artisan enduits"
        />
      </Helmet>

      <div className="hero-overlay">
        {/* Données structurées pour le référencement local */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Les Enduits d'Eugénie",
              "image": "https://les-enduits-deugenie.fr/assets/1.webp",
              "description": "Artisan spécialisée dans la maçonnerie fine, l'application d'enduits écologiques (terre, chaux, plâtre, béton ciré) pour un rendu unique, sain et durable.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Le Vigan",
                "addressRegion": "Occitanie",
                "postalCode": "30120",
                "streetAddress": "Adresse de l'entreprise"
              },
              "telephone": "+33652220297",
              "email": "contact@les-enduits-deugenie.fr",
              "url": "https://les-enduits-deugenie.fr",
              "areaServed": "Cévennes, Montpellier, Avignon"
            }
          `}
        </script>

        <div className="logocontainer">
          <Link to="/">
            <img
              src={logo}
              alt="Les Enduits d'Eugénie - Expert en béton ciré et enduits naturels"
              className={`hero-logo ${isAside ? 'logo-aside' : ''}`}
            />
          </Link>
        </div>
        <h1>Béton ciré et enduits naturels</h1>
        <h2>Donnez à vos espaces de vie une finition unique, durable et écologique</h2>
        <p>Experte en maçonnerie fine, enduits naturels et décoratifs, j'interviens au Vigan, dans les Cévennes, autour de Montpellier et d'Avignon pour des résultats de qualité, précis et durables.</p>
        <Link to="/contact">
          <button className="cta-button">Demandez un devis gratuit</button>
        </Link>
        <div className="footer">
          <h5>
            <span role="img" aria-label="Téléphone">📞  </span>
            <a href="tel:0652220297">06 52 22 02 97</a>
          </h5>
          <h5>
            <span role="img" aria-label="Email">✉️  </span>
            <a href="mailto:contact@les-enduits-deugenie.fr">contact@les-enduits-deugenie.fr</a>
          </h5>
          <h5>
            <span role="img" aria-label="Localisation">📍  </span>
            <a href="https://www.google.com/maps/place/Les+Enduits+d%27Eugenie/data=!4m7!3m6!1s0x4e8e413637b89c63:0x934cfce86c46a298!8m2!3d43.7877091!4d4.1981143!16s%2Fg%2F11wtf3dklx!19sChIJY5y4NzZBjk4RmKJGbOj8TJM?authuser=0&hl=fr&rclk=1" target="_blank" rel="noopener noreferrer">
              Le Vigan en Cévennes - Montpellier - Avignon
            </a>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;