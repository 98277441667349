import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeroSection from '../HeroSection.js';
import APropos from './APropos.js';
import Contact from './Contact.js';
import Galerie from './Galerie.js';
import Service from './Service.js';
import Blog from './Blog.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './styles/Transitions.css';

function MainContent({ onOpenLightbox }) {
  const [isAside, setIsAside] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  // Détection de la largeur de l'écran et ajustement de la disposition
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (location.pathname === '/') {
        setIsAside(false); // Pas d'aside sur la page d'accueil
      } else {
        setIsAside(!mobile); // HeroSection devient aside sauf sur mobile
      }
    };

    handleResize(); // Initialisation lors du premier rendu
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  // Configuration des balises meta pour chaque route
  const metaConfig = {
    '/': {
      title: "Les Enduits d'Eugénie - Béton ciré, enduits naturels et maçonnerie fine au Vigan, autour de Montpellier et Avignon",
      description:
        "Experte en béton ciré, enduits naturels et maçonnerie fine, Les Enduits d'Eugénie intervient au Vigan, dans les Cévennes, autour de Montpellier et d'Avignon pour des finitions uniques et durables.",
      keywords:
        "béton ciré, maçonnerie, enduits naturels, Le Vigan, Cévennes, Montpellier, Avignon, enduits décoratifs, artisan enduits",
      image: "https://les-enduits-deugenie.fr/assets/1.webp",
    },
    '/apropos': {
      title: "À Propos de Sarah Eugenie Bouchemella - Les Enduits d'Eugénie",
      description:
        "Découvrez le parcours de Sarah, formée en éco-construction, passionnée par les enduits (terre, chaux, plâtre) et le béton ciré. Installée au Vigan, elle intervient dans un rayon de 200 km autour de Montpellier.",
      keywords:
        "Sarah Eugenie Bouchemella, maçonnerie, éco-construction, enduits terre, enduits chaux, béton ciré, stuc, badigeons, Cévennes, Montpellier, Avignon",
      image: "https://les-enduits-deugenie.fr/assets/Apropos.webp",
    },
    '/services': {
      title: "Nos Services - Enduits de finition, Béton Ciré & Décoration Murale",
      description:
        "Sublimez vos espaces de vie avec nos enduits de finition, béton ciré, badigeons et décorations murales (stuc, sgraffito). Des solutions écologiques adaptées à tous vos projets en neuf ou rénovation.",
      keywords:
        "enduits de finition, maçonnerie, béton ciré, badigeons, stuc, sgraffito, décoration murale, artisanat écologique, rénovation, construction, enduits terre, enduits chaux",
      image: "https://les-enduits-deugenie.fr/assets/2.webp",
    },
    '/galerie': {
      title: "Galerie - Réalisations en Enduits Naturels & Décoratifs",
      description:
        "Explorez nos projets : travaux en cours ou chantiers terminés, enduits à la chaux, enduits terre, béton ciré et badigeons. Inspirez-vous pour vos prochains travaux de rénovation ou de construction neuve.",
      keywords:
        "galerie enduits, enduits naturels, enduit chaux, enduit terre, béton ciré, badigeons, décoration murale, éco-construction",
      image: "https://les-enduits-deugenie.fr/assets/19.webp",
    },
    '/contact': {
      title: "Contact - Les Enduits d'Eugénie",
      description:
        "Besoin d'un devis ou de conseils ? Contactez-nous pour vos projets d'enduits naturels, béton ciré et décorations murales autour de Montpellier, Cévennes et Avignon.",
      keywords:
        "contact enduits, devis, béton ciré, artisan éco-construction, badigeons, enduits chaux, enduits terre, stuc, Cévennes, Montpellier, Avignon",
      image: "https://les-enduits-deugenie.fr/assets/logo2.webp",
    },
    '/blog': {
      title: "Blog - Conseils & Astuces sur les Enduits Naturels",
      description:
        "Découvrez nos articles sur l'éco-construction, les enduits naturels, le béton ciré, la décoration murale et bien plus. Trouvez l'inspiration pour vos projets.",
      keywords:
        "blog enduits, éco-construction, tutoriels béton ciré, enduits chaux, enduits terre, décoration murale, badigeons, stuc",
      image: "https://les-enduits-deugenie.fr/assets/logo2.webp",
    },
  };

  const currentMeta = metaConfig[location.pathname] || {
    title: "Les Enduits d'Eugénie",
    description: "Artisanat de qualité pour vos murs et vos espaces de vie.",
    keywords: "enduits, béton ciré, chaux, terre, décoration, rénovation",
    image: "https://les-enduits-deugenie.fr/assets/logo2.webp",
  };

  return (
    <div className={`main-container ${isAside ? 'with-aside' : ''}`}>
      {/* Balises Helmet pour SEO */}
      <Helmet>
        <title>{currentMeta.title}</title>
        <meta name="description" content={currentMeta.description} />
        <meta name="keywords" content={currentMeta.keywords} />
        <meta property="og:title" content={currentMeta.title} />
        <meta property="og:description" content={currentMeta.description} />
        <meta property="og:image" content={currentMeta.image} />
        <meta
          property="og:url"
          content={`https://les-enduits-deugenie.fr${location.pathname}`}
        />
      </Helmet>

      {/* HeroSection (peut devenir aside sauf sur mobile) */}
      {!(isMobile && location.pathname !== '/') && <HeroSection isAside={isAside} />}

      {/* Contenu des pages */}
      <div className="page-content">
        <TransitionGroup className="page-transition-container">
          <CSSTransition
            key={location.key}
            classNames="slide"
            timeout={500}
            unmountOnExit
          >
            <Routes location={location}>
              <Route path="/apropos" element={<APropos />} />
              <Route path="/services" element={<Service />} />
              <Route
                path="/galerie"
                element={<Galerie onOpenLightbox={onOpenLightbox} />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default MainContent;
